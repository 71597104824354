<template>
    <div class=container>
<h1> 
Cylinders rendered with 2D texture mapping (left) and 3D texture mapping (right) using images from the BTF database. </h1>
<p>
From top to bottom, the samples rendered are Sample 11 (plaster), Sample 8 (pebbles), Sample 45 (concrete),
Sample 28 (crumpled paper),
Sample 19 (rug),
and Sample 56 (wood).
</p>


<img src="https://cave.cs.columbia.edu/old/software/curet/gif/figcyl1.gif">
<img src="https://cave.cs.columbia.edu/old/software/curet/gif/figcyl2.gif">

<hr>
<router-link to="/repository/CURET/btfm">[Return to BTF Measurement Section] </router-link>


    </div>
</template>

<script>
export default {

}
</script>

<style>
.container{
    width:70%;
    margin-left:auto;
    margin-right:auto;
    text-align: left;
	font-size: 18px;
}
.table{
        margin:auto;
}
</style>